import { doc, getDoc, updateDoc } from "firebase/firestore";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

import { DeleteIcon, DevicesIcon, RotateIcon } from "assets/images/common";
import { ImageEditor, SectionWrapper } from "components";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { AUTH_MODE_TYPE } from "models/auth.model";
import { selectAuthMode } from "store/auth/auth.selector";
import { globalActions } from "store/global/global.slice";
import {
  selectOrganisation,
  selectPossibleEditOrganisation,
} from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";
import { db, uploadFromBlobAsync } from "utils/firebase.util";

export const HeaderSection = () => {
  const [logoImage, setLogoImage] = useState<Blob | undefined>(undefined);
  const [posterImage, setPosterImage] = useState<Blob | undefined>(undefined);
  const [coverHeight, setCoverHeight] = useState(0);
  const [isMobileView, setMobileView] = useState(false);

  const dispatch = useAppDispatch();

  const coverRef = useRef<HTMLDivElement>(null);
  const possibleEditOrganisation = useAppSelector(
    selectPossibleEditOrganisation
  );
  const authMode = useAppSelector(selectAuthMode);
  const isAdmin = authMode === AUTH_MODE_TYPE.ADMIN;

  const { logoUrl, posterUrl, id } = useAppSelector(selectOrganisation);
  const desktopPosterUrl =
    posterUrl?.split(" ").find((url) => _.includes(url, "desktop")) || "";
  const mobilePosterUrl =
    posterUrl?.split(" ").find((url) => _.includes(url, "mobile")) || "";

  const handleUploadLogo = useCallback(
    async (logoImage: Blob) => {
      dispatch(globalActions.setPageLoading(true));

      try {
        const url = await uploadFromBlobAsync({
          blob: logoImage,
          name: "avatar",
          folder: `workspaces/${id}/assets`,
        });

        const data = {
          logoUrl: url as string,
        };

        dispatch(
          organisationActions.updateOrganisation({
            organisationId: id,
            input: data,
            callback: async () => {
              const docRef = doc(db, "users", id);
              const docData = await getDoc(docRef);

              if (docData.exists()) {
                await updateDoc(docRef, {
                  avatar: url,
                });
              }
            },
          })
        );

        dispatch(globalActions.setPageLoading(false));
      } catch (_) {
        dispatch(
          globalActions.pushNotification({
            type: "error",
            message: "Update logo failed",
          })
        );

        dispatch(globalActions.setPageLoading(false));
      }
    },
    [dispatch, id]
  );

  const handleClearPoster = () => {
    try {
      dispatch(
        organisationActions.updateOrganisation({
          organisationId: id,
          input: { posterUrl: "" },
        })
      );
    } catch (_) {
      dispatch(
        globalActions.pushNotification({
          type: "error",
          message: "Update poster failed",
        })
      );
    }
  };

  const handleUploadPoster = useCallback(
    async (posterImage: Blob) => {
      dispatch(globalActions.setPageLoading(true));

      try {
        const url = await uploadFromBlobAsync({
          blob: posterImage,
          name: isMobileView ? "mobile-poster" : "desktop-poster",
          folder: `workspaces/${id}/assets`,
        });

        const data = {} as { posterUrl: string };

        const posters = posterUrl?.split(" ") || [];

        if (posters.length) {
          const index = _.findIndex(posters, (url) =>
            _.includes(url, `${isMobileView ? "mobile" : "desktop"}-${id}`)
          );
          if (index !== -1) {
            posters.splice(index, 1, url as string);
          } else {
            posters.push(url as string);
          }

          data.posterUrl = posters.join(" ");
        } else {
          data.posterUrl = url as string;
        }

        dispatch(
          organisationActions.updateOrganisation({
            organisationId: id,
            input: data,
          })
        );

        setPosterImage(undefined);
        dispatch(globalActions.setPageLoading(false));
      } catch (_) {
        dispatch(
          globalActions.pushNotification({
            type: "error",
            message: "Update poster failed",
          })
        );

        dispatch(globalActions.setPageLoading(false));
      }
    },
    [dispatch, id, isMobileView, posterUrl]
  );

  const handleResize = () => {
    if (coverRef.current) {
      setCoverHeight(coverRef.current.offsetWidth / 4);
    }
  };

  useEffect(() => {
    if (logoImage && id) {
      handleUploadLogo(logoImage);
    }
  }, [handleUploadLogo, id, logoImage]);

  useEffect(() => {
    if (posterImage && id) {
      handleUploadPoster(posterImage);
    }
  }, [handleUploadPoster, id, posterImage]);

  useEffect(() => {
    handleResize();

    if (typeof window !== "undefined") {
      window.onresize = handleResize;
    }
  }, []);

  return (
    <SectionWrapper>
      <Container disabled={isAdmin || !possibleEditOrganisation}>
        <CoverWrapper
          ref={coverRef}
          height={posterUrl && !isMobileView ? "auto" : `${coverHeight}px`}
          className={isMobileView ? "mobile-view" : undefined}
        >
          <LeftBox className="left-box">
            <div>Full size</div>
            <div>minimum 2240x368 px</div>
          </LeftBox>

          <ImageEditor
            className="cover-upload"
            onSave={setPosterImage}
            url={
              isMobileView
                ? mobilePosterUrl || desktopPosterUrl
                : desktopPosterUrl || mobilePosterUrl
            }
            width={isMobileView ? 540 : 1200}
            height={300}
            uploadText="Upload a SVG, PNG, JPG or GIF picture for cover.
      Image must be 2560 x 1152 px. Max 6 MB."
            clearImage
          />

          {posterUrl && (
            <ActionsWrapper className="actions">
              <ButtonWrapper onClick={handleClearPoster}>
                <DeleteIcon />
              </ButtonWrapper>

              {false && (
                <ButtonWrapper>
                  <RotateIcon />
                </ButtonWrapper>
              )}

              <ButtonWrapper onClick={() => setMobileView(!isMobileView)}>
                <DevicesIcon color={isMobileView ? "#A18C66" : undefined} />
              </ButtonWrapper>
            </ActionsWrapper>
          )}
        </CoverWrapper>

        <AvatarWrapper>
          <ImageEditor
            className="avatar-upload"
            onSave={setLogoImage}
            url={logoUrl}
          />
        </AvatarWrapper>
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  margin: -24px;
  border-radius: 12px;
  overflow: hidden;
  pointer-events: ${({ disabled }: { disabled?: boolean }) =>
    disabled ? "none" : "unset"};
`;

const LeftBox = styled.div`
  display: none;
  height: 100%;
  padding: 20px;
  background: #dfe1e680;
  color: #7c8698;
  font-size: 14px;

  & div:first-child {
    font-weight: 600;
  }
`;

const CoverWrapper = styled.div`
  position: relative;
  display: flex;
  height: ${({ height }: { height?: string }) => height};
  min-height: 200px;
  padding: 4px;

  .cover-upload {
    flex: 1;
    border: none;
    border-bottom: 1px dashed #dfe1e6;
    background: #dfe1e6;

    &.has-image {
      border: none;
      background: transparent;
      min-height: 100px;

      .image-view {
        width: 100%;
        height: 100%;

        button {
          top: unset;
          right: 8px;
          bottom: 8px;
          height: fit-content;
        }

        img {
          width: 100%;
          height: auto;
          height: ${({ height }: { height?: string }) =>
            height === "auto" ? "auto" : "100%"};
          object-fit: cover;
        }
      }
    }
  }

  &.mobile-view {
    .cover-upload {
      flex: 1;
    }

    .actions {
      position: relative;
      width: 300px;
      height: 100%;

      ${(props) => props.theme.breakpoints.down("xl")} {
        width: 200px;
      }

      &::after {
        display: block;
      }
    }

    .left-box {
      display: block;
      width: 300px;

      ${(props) => props.theme.breakpoints.down("xl")} {
        width: 200px;
      }
    }
  }
`;

const AvatarWrapper = styled.div`
  position: relative;
  height: 96px;

  .avatar-upload {
    position: absolute;
    top: -48px;
    left: 32px;
    width: 96px;
    height: 96px;
    border-radius: 8px;

    &.has-image {
      top: -58px;
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
      left: 20px;
    }
  }
`;

const ActionsWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 6px;
  width: fit-content;
  height: fit-content;
  padding: 20px;

  &::after {
    position: absolute;
    top: 0px;
    right: 0px;
    display: none;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #dfe1e6;
    content: "";
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border: 1px solid #dfe1e6;
  border-radius: 4px;
  background: #ffffff;
  cursor: pointer;
  z-index: 1;
  transition: all 0.5s;

  &:hover {
    transform: scale(1.05);
  }

  svg {
    width: 14px;
    height: 14px;
  }
`;
