import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Dialog } from "@mui/material";
import commonApi from "api/common.api";
import feedApi from "api/feed.api";
import { ConfirmSendMailIcon } from "assets/images/company";
import {
  Button,
  CheckBox,
  GroupRadioButton,
  GroupText,
  HorizontalBox,
  HorizontalLine,
  Link,
  Text,
} from "components";
import GroupTextField from "components/UI/Form/Input/GroupTextField";
import { MESSAGE_CODE, NOTI_CODE } from "constants/firestore.const";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { RadioButtonData, SelectItem } from "models/common.model";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { selectFeedDetail } from "store/feed/feed.selector";
import { selectActiveWorkspace } from "store/workspace/workspace.selector";
import styled from "styled-components/macro";
import { encrypt, errorNotification } from "utils/app.util";
import { createChat } from "utils/chat.util";
import { db } from "utils/firebase.util";
import { formatCurrency } from "utils/system.util";
import * as yup from "yup";

const TIME_INVEST_RADIO_DATA = [
  { value: "Now", label: "Now" },
  { value: "This month", label: "This month" },
  { value: "This quarter", label: "This quarter" },
  { value: "This Year", label: "This Year" },
];

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ContactFounderModal = ({ isOpen, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(1);
  const [investorTypes, setInvestorTypes] = useState<RadioButtonData[]>([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const { id, contactEmail, displayName, logoUrl, currency } =
    useAppSelector(selectFeedDetail);
  const [investmentInstrument, setInvestmentInstrument] = useState<string>("");
  const [timeline, setTimeLine] = useState<string>("");
  const [conditions, setConditions] = useState<{
    term: boolean;
    service: boolean;
  }>({ term: false, service: false });
  const [roomId, setRoomId] = useState("");

  const navigate = useNavigate();

  const { id: workspaceId, displayName: workspaceName } = useAppSelector(
    selectActiveWorkspace
  );

  const schema = yup.object().shape({
    investor_name: yup.string().required(),
    investor_email: yup.string().email().required(),
    ticket_size: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSendMessage = async (investData: {
    ticket: number;
    instrument: string;
    timeline: string;
  }) => {
    try {
      await createChat({
        user1: {
          id: workspaceId,
          displayName: workspaceName,
          type: "investor",
        },
        user2: { id, displayName, avatar: logoUrl, type: "founder" },
        handleRoomId: async (roomId) => {
          const messageRef = collection(db, "messages");
          const notificationRef = collection(db, "notifications");
          const roomRef = doc(db, "rooms", roomId);

          const roomDoc = await getDoc(roomRef);

          if (roomDoc.exists()) {
            setRoomId(roomId);

            const { instrument, ticket, timeline } = investData;
            const investMessage = "You have a new Investment Proposal";

            const newMessage = {
              message: encrypt(
                `${MESSAGE_CODE.INVEST}__${instrument}__${formatCurrency(
                  ticket,
                  currency
                )}__${timeline}__${investMessage}`
              ),
              roomId,
              senderId: workspaceId,
              createdAt: new Date().valueOf(),
              isRead: false,
            };

            const unread = roomDoc.data().unread;
            await updateDoc(roomRef, {
              updatedAt: new Date().valueOf(),
              unread: {
                ...unread,
                [id]: unread[id] + 1,
              },
              lastMessage: newMessage,
            });
            await addDoc(messageRef, newMessage);
            await addDoc(notificationRef, {
              code: `${NOTI_CODE.CHAT_INVEST}__${workspaceId}__${roomId}`,
              receiverId: id,
              createdAt: new Date().valueOf(),
              isRead: false,
              unreadCount: 1,
            });
          }
        },
      });

      // eslint-disable-next-line no-empty
    } catch (_) {}
  };

  const handleContactFounder = (formData: FieldValues) => {
    setIsSubmit(true);
    const { investor_name, investor_email, ticket_size } = formData;
    const input = {
      investor_name,
      investor_email,
      ticket_size,
      investment_instrument: investmentInstrument,
      timeline,
      to: contactEmail,
    };
    feedApi
      .sendProspectContact(id, input)
      .then(() => {
        handleSendMessage({
          ticket: ticket_size,
          instrument: investmentInstrument,
          timeline,
        }).then(() => {
          setStep(2);
          setIsSubmit(false);
          setValue("ticket_size", "");
          setConditions({
            term: false,
            service: false,
          });
        });
      })
      .catch(() => {
        setIsSubmit(false);
        dispatch(errorNotification("Send contact founder failed"));
      });
  };

  const handleCheckTerm = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setConditions({ ...conditions, [event.target.name]: checked });
  };

  useEffect(() => {
    commonApi.fundingInstruments().then((res) => {
      const { fundingInstruments } = res;
      if (fundingInstruments) {
        setInvestorTypes(
          fundingInstruments.map((item: SelectItem) => ({
            label: item.name,
            value: item.name,
          }))
        );
      }
    });
  }, []);

  return (
    <BaseDialog
      open={isOpen}
      onClose={() => {
        onClose();
        setStep(1);
        reset();
      }}
      maxWidth="lg"
    >
      {step === 1 && (
        <Wrapper>
          <HeaderBox>
            <Text size="lg">
              {`Send your investment proposal to the founder of ${displayName}`}
            </Text>
          </HeaderBox>

          <Form onSubmit={handleSubmit(handleContactFounder)}>
            <GroupTextField
              fieldName="Your name"
              placeholder="Investor"
              name="investor_name"
              formRef={register}
              error={errors.investor_name?.message as string}
            />

            <GroupTextField
              fieldName="Your work email"
              placeholder="investor@fund.com"
              name="investor_email"
              formRef={register}
              error={errors.investor_email?.message as string}
            />

            <GroupTextField
              fieldName="Ticket you want to invest in this company"
              placeholder={`Amount in ${currency?.symbol || "€"}`}
              name="ticket_size"
              formRef={register}
              error={errors.ticket_size?.message as string}
            />
            <GroupBox>
              <Text size="md" className="title-text">
                Instrument
              </Text>
              <ListBox>
                <GroupRadioButton
                  groupName="investmentInstrument"
                  data={investorTypes}
                  handleOnSelect={setInvestmentInstrument}
                />
              </ListBox>
            </GroupBox>
            <GroupBox>
              <Text size="md" className="title-text">
                When are-you ready to invest?
              </Text>
              <ListBox>
                <GroupRadioButton
                  groupName="timeLine"
                  data={TIME_INVEST_RADIO_DATA}
                  handleOnSelect={setTimeLine}
                />
              </ListBox>
            </GroupBox>
            <HorizontalLine />

            <GroupBox>
              <HorizontalBox>
                <CheckBox
                  mainsize={22}
                  label=""
                  name="term"
                  onChange={handleCheckTerm}
                  checked={conditions.term}
                />
                <GroupText>
                  <Text size="md">I agree to the</Text>
                  <Link
                    target="_blank"
                    href="https://monacofoundry.notion.site/MYFOUNDRY-FREMIUM-TERMS-CONDITIONS-b1a296592ed742749026f082770e77ef"
                  >
                    terms and conditions
                  </Link>
                </GroupText>
              </HorizontalBox>

              <HorizontalBox>
                <CheckBox
                  mainsize={22}
                  label=""
                  name="service"
                  onChange={handleCheckTerm}
                  checked={conditions.service}
                />
                <Text size="md">
                  I accept the sending of advertising material related to
                  products and services, via email (optional)
                </Text>
              </HorizontalBox>
            </GroupBox>

            <Button
              type="submit"
              loading={isSubmit}
              disabled={!(conditions.term && investmentInstrument && timeline)}
            >
              Send
            </Button>
          </Form>
        </Wrapper>
      )}
      {step === 2 && (
        <Wrapper>
          <HeaderBox>
            <Text size="lg">
              Thank you for sending your investment proposal!
            </Text>
          </HeaderBox>
          <Form onSubmit={(event) => event.preventDefault()}>
            <ConfirmSendMailBox>
              <ConfirmSendMailIcon />
            </ConfirmSendMailBox>
            <Text size="md" className="text-center">
              The founder already received it and will get back to you shortly
              to continue the conversation.
            </Text>
            <Box>
              <Button onClick={() => navigate(`/chats/${roomId}`)}>
                Chat with the Founder
              </Button>

              <Button
                onClick={() => {
                  onClose();
                  setStep(1);
                }}
              >
                Discover other start-ups
              </Button>
            </Box>
          </Form>
        </Wrapper>
      )}
    </BaseDialog>
  );
};

export default ContactFounderModal;

const BaseDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 16px;
  }
`;

const HeaderBox = styled.div`
  text-align: center;
`;

const Wrapper = styled.div`
  width: clamp(320px, 100vw, 480px);
  padding: 40px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;

  .text-center {
    text-align: center;
  }

  .button-wrapper {
    width: 100%;
    margin-top: 20px;

    & button {
      width: 100%;
    }
  }
`;

const GroupBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .title-text {
    color: rgb(55, 59, 89);
    font-size: 14px;
    font-weight: 600;
  }
`;

const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ConfirmSendMailBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  background-color: #f4f5f7;
  border-radius: 32px;
`;
