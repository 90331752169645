import { loadingTypeAdd, loadingTypeRemove } from "../../utils/loading.utils";

const initialState = {
  data: null,
  loading: [],
  err: "",
};

const adminReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case "ADMIN_STATS_REQUEST":
      return {
        ...state,
        loading: loadingTypeAdd(state.loading, type),
      };
    case "ADMIN_STATS_SUCCESS":
      return {
        ...state,
        data: payload,
        loading: loadingTypeRemove(state.loading, type),
        err: "",
      };
    case "ADMIN_STATS_ERROR":
      return {
        ...state,
        loading: loadingTypeRemove(state.loading, type),
      };
    default:
      return state;
  }
};

export default adminReducer;
