import { Divider } from "@mui/material";
import { DragDropField } from "components/UI/Form/Input/DragDropField";
import { Text } from "components";
import { useCallback, useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import styled from "styled-components";
import { useTheme } from "@mui/material/styles";
import * as yup from "yup";

import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { AUTH_MODE_TYPE } from "models/auth.model";
import { SelectItemTask } from "models/common.model";
import { Fundraising } from "models/fundraising.model";
import { selectAuthMode } from "store/auth/auth.selector";
import {
  selectFundraising,
  selectPossibleEditFundraising,
} from "store/fundraising/fundraising.selector";
import { fundraisingActions } from "store/fundraising/fundraising.slice";
import { globalActions } from "store/global/global.slice";
import { selectOrganisation } from "store/organisation/organisation.selector";
import { uploadFromBlobAsync } from "utils/firebase.util";
import {
  validateMaxSizeFile,
  validateVideoDurationTime,
} from "utils/system.util";
import { MediaFrame } from "views/main/Company/components";
import { FundraisingFieldInput } from "./FundraisingFieldInput";

interface Props {
  inputFieldName: string;
  inputName: string;
  inputPlaceholder?: string;
  inputSchema: yup.AnySchema;
  attachmentName: string;
}

export const FundraisingMediaEditor = ({
  inputFieldName,
  inputName,
  inputPlaceholder,
  inputSchema,
  attachmentName,
}: Props) => {
  const [file, setFile] = useState<File | undefined>();

  const dispatch = useAppDispatch();
  const { id: organisationId, videoUrl } = useAppSelector(selectOrganisation);
  const selectedFundraising = useAppSelector(selectFundraising);
  const isPossibleEditFundraising = useAppSelector(
    selectPossibleEditFundraising
  );

  const authMode = useAppSelector(selectAuthMode);
  const isAdmin = authMode === AUTH_MODE_TYPE.ADMIN;

  const inputData = selectedFundraising[inputName as keyof Fundraising];
  const attachment = selectedFundraising[attachmentName as keyof Fundraising];
  const theme = useTheme();

  const getFolderName = useCallback(
    (fileType: string) => {
      if (attachmentName === "mfScoreUrl") return "report";
      if (fileType.includes("image")) return "image";
      if (fileType.includes("pdf")) return "pdf";
      if (fileType.includes("video")) return "video";
      return "";
    },
    [attachmentName]
  );

  const handleUpdateField = async (
    data: FieldValues | { [key: string]: SelectItemTask[] }
  ) => {
    if (data?.[attachmentName] === attachment) return;

    try {
      if (selectedFundraising?.id) {
        dispatch(
          fundraisingActions.updateFundraising({
            id: selectedFundraising.id,
            input: data,
          })
        );
      }
    } catch (err) {
      //throw err as Error;
    }
  };

  const handleFileBrowser = async (file: File | undefined) => {
    if (file) {
      let validData = {
        isValid: true,
        errorMessage: "",
      };

      if (file.type.includes("video")) {
        validData = await validateVideoDurationTime(file);
      } else {
        validData = validateMaxSizeFile(file);
      }

      const { isValid, errorMessage } = validData;

      if (isValid) {
        setFile(file);
      } else {
        setFile(undefined);
        dispatch(
          globalActions.showNotificationModal({
            isShow: true,
            title: errorMessage,
            onSubmit: (onClose) => {
              onClose();
            },
          })
        );
      }
      return;
    }
  };

  useEffect(() => {
    if (file && selectedFundraising?.id) {
      try {
        dispatch(globalActions.setPageLoading(true));

        uploadFromBlobAsync({
          blob: file,
          name: "pitch-deck",
          folder: `workspaces/${organisationId}/fundraising/${
            selectedFundraising.id
          }/${getFolderName(file.type)}`,
        }).then((url) => {
          dispatch(
            fundraisingActions.updateFundraising({
              id: selectedFundraising.id,
              input: {
                [attachmentName]: url,
              },
            })
          );
          setFile(undefined);
          dispatch(globalActions.setPageLoading(false));
        });
      } catch (error) {
        setFile(undefined);
        dispatch(globalActions.setPageLoading(false));
      }
    }
  }, [
    attachmentName,
    dispatch,
    file,
    getFolderName,
    organisationId,
    selectedFundraising.id,
  ]);

  return (
    <Wrapper>
      <FundraisingFieldInput
        fieldName={inputFieldName}
        name={inputName}
        placeholder={inputPlaceholder}
        yupSchema={inputSchema}
      />
      {videoUrl && videoUrl === inputData && (
        <Text color={theme.palette.error.main} size="md">
          {
            "Do you have a specific video attached to this Fundraising round? Then add a Youtube or Vimeo link! Please, don’t add the same video link if you have already shared it in the “Settings” tab, better upload a pitch deck."
          }
        </Text>
      )}
      {inputData && <MediaFrame url={inputData as string} />}

      <Divider
        sx={{
          borderWidth: "0.5px",
          borderColor: "#ebecef",
          color: "#7C8698",
        }}
      >
        OR
      </Divider>

      <DragDropField
        fieldName="Attachment"
        label={`Upload a file to illustrate your pitch deck.
          BMP, PNG, JPG or GIF for photo. 
          PDF, DOC, DOCX, PPT for document.`}
        handleChange={(file) => handleFileBrowser(file)}
        types={[
          "bmp",
          "png",
          "jpg",
          "jpeg",
          "gif",
          "pdf",
          "doc",
          "docx",
          "ppt",
          "pptx",
        ]}
        handleRemoveUrl={() => {
          handleUpdateField({ [attachmentName]: "" });
        }}
        url={attachment as string}
        disabled={isAdmin || !isPossibleEditFundraising}
      />

      {attachment && <MediaFrame url={attachment as string} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
