import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { BasicTable } from "components";
import { fDate } from "utils/time.util";

const CONFIG = [
  {
    name: "Created",
    width: "auto",
    fieldName: "createdAt",
    // isSort: true,
  },
  {
    name: "Email",
    width: "auto",
    fieldName: "email",
    // isSort: true,
  },
];

export function AdminStatsPage() {
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.admin.data);

  useEffect(() => {
    dispatch({ type: "ADMIN_STATS_REQUEST" });
  }, [dispatch]);

  const usersTableData = useMemo(() => {
    return stats?.users?.map((d) => ({
      ...d,
      createdAt: fDate(d.createdAt, "DD/MM/YYYY"),
    }));
  }, [stats]);

  const emailCountTotal = useMemo(() => {
    return stats?.users?.length || 0;
  }, [stats]);

  const emailCountToday = useMemo(() => {
    const todayFormatted = fDate(Date.now(), "DD/MM/YYYY");
    const count =
      stats?.users?.filter((user) => {
        const userCreatedAtFormatted = fDate(user.createdAt, "DD/MM/YYYY");
        return userCreatedAtFormatted === todayFormatted;
      }).length || 0;

    return count;
  }, [stats]);

  return (
    <Wrapper>
      <StatsWrapper>
        <StatsOuter>
          <StatsInner>
            <Stat>
              {emailCountTotal}
              <StatAppend>Total</StatAppend>
            </Stat>
          </StatsInner>
        </StatsOuter>
        <StatsOuter>
          <StatsInner>
            <Stat>
              {emailCountToday}
              <StatAppend>Today</StatAppend>
            </Stat>
          </StatsInner>
        </StatsOuter>
      </StatsWrapper>
      <BasicTable
        config={CONFIG}
        data={usersTableData || []}
        onSort={() => ({})}
      />
    </Wrapper>
  );
}

const StatsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    justify-content: center;
  }
`;

const StatsOuter = styled.div`
  min-height: 180px;
  min-width: 240px;
  margin-left: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid rgb(216, 217, 223);
  ${(props) => props.theme.breakpoints.down("sm")} {
    min-height: 100px;
    min-width: 180px;
  }
`;

const StatsInner = styled.div`
  padding: 24px;
`;

const Stat = styled.span`
  font-size: 86px;
  font-weight: 600;
  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: 66px;
  }
`;
const StatAppend = styled.span`
  font-size: 12px;
  font-weight: 300;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px;
  padding-bottom: 10px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }

  & > .MuiPaper-root {
    flex: 1;
  }
`;
