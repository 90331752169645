import { yupResolver } from "@hookform/resolvers/yup";
import { linkClasses } from "@mui/material";
import { EyeClosedIcon, EyeIcon } from "assets/images/common";
import { Button, Link } from "components";
import GroupTextField from "components/UI/Form/Input/GroupTextField";
import { NAMESPACE_ID } from "constants/app.const";
import {
  getAuth,
  signInWithEmailAndPassword,
  UserCredential,
} from "firebase/auth";
import { useAppDispatch } from "hooks/useHook";
import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { authActions } from "store/auth/auth.slice";
import { globalActions } from "store/global/global.slice";
import styled from "styled-components/macro";
import { LocalStorage } from "utils/localStorage.util";
import { parseToken } from "utils/system.util";
import * as yup from "yup";

const SignInForm = () => {
  const dispatch = useAppDispatch();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const PasswordIcon = showPassword ? EyeClosedIcon : EyeIcon;

  const navigate = useNavigate();

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().min(6).max(20).required(),
  });

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = async (data: FieldValues) => {
    try {
      setSubmitLoading(true);
      const { email, password } = data;
      const userCredentail: UserCredential = await signInWithEmailAndPassword(
        getAuth(),
        email,
        password
      );
      if (userCredentail.user) {
        const userToken = await userCredentail.user.getIdToken();
        const tokenObject = parseToken(userToken as string);
        if (tokenObject) {
          const namespaceId = tokenObject?.ws_ns || tokenObject?.ns;
          if (namespaceId) {
            dispatch(authActions.updateNamespaceId(tokenObject?.ws_ns));
            LocalStorage.set(namespaceId, NAMESPACE_ID);
          }
        }
        dispatch(authActions.userLogin(userToken));
        setSubmitLoading(false);
      }
    } catch (err) {
      const errorObj = new Error(JSON.stringify(err));
      const errText = errorObj?.message?.includes("auth/network-request-failed")
        ? "It looks like you are not connect to Internet. Please, verify your connection."
        : "The email or password is incorrect. Please try again.";
      dispatch(
        globalActions.pushNotification({
          message: errText,
          type: "error",
        })
      );
      setSubmitLoading(false);
      //throw err as Error;
    }
  };

  return (
    <Wrapper onSubmit={handleSubmit(handleLogin)}>
      <GroupTextField
        fieldName="Email"
        placeholder="email@themarkchallenge.com"
        name="email"
        formRef={register}
        error={errors.email?.message as string}
      />

      <GroupTextField
        fieldName="Password"
        name="password"
        type={showPassword ? "text" : "password"}
        formRef={register}
        icon={
          <PasswordIconWrapper onClick={() => setShowPassword(!showPassword)}>
            <PasswordIcon />
          </PasswordIconWrapper>
        }
        error={errors.password?.message as string}
      />

      <Footer>
        <Link
          onClick={() => {
            const email = getValues("email");
            navigate({
              pathname: "/forgot-password",
              search: email && `?email=${encodeURIComponent(email)}`,
            });
          }}
        >
          Reset password
        </Link>
        <Button type="submit" loading={submitLoading}>
          Sign In
        </Button>
      </Footer>
    </Wrapper>
  );
};

export default SignInForm;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 30px;
  .${linkClasses.root} {
    color: #a5a7b4;
    text-decoration-color: #a5a7b4;
  }
  & button[type="submit"] {
    width: 180px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const PasswordIconWrapper = styled.span``;
