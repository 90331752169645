import { createSelector } from "@reduxjs/toolkit";
import { NAMESPACE_ID } from "constants/app.const";
import { LocalStorage } from "utils/localStorage.util";
import { AuthState, initialState } from "./auth.slice";

const selectDomain = (state: { auth: AuthState }) => state.auth || initialState;

export const selectAuthLoading = createSelector(
  [selectDomain],
  (auth) => auth.loading
);

export const selectAuthMode = createSelector(
  [selectDomain],
  (auth) => auth.mode
);

export const selectWorkspaceType = createSelector(
  [selectDomain],
  (auth) => auth.workspaceType
);

export const selectWorkspaceUpdate = createSelector(
  [selectDomain],
  (auth) => auth.workspaceUpdate
);

export const selectNamespaceId = createSelector(
  [selectDomain],
  (auth) => auth.namespaceId || LocalStorage.get(NAMESPACE_ID) || ""
);
