import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import ConfirmModal from "components/Feature/ConfirmModal";
import { MESSAGE_CODE, NOTI_CODE } from "constants/firestore.const";
import { useAppSelector } from "hooks/useHook";
import { selectSelectedFund as selectSelectedFundOnFeed } from "store/feed/feed.selector";
import { selectActiveWorkspace } from "store/workspace/workspace.selector";
import { encrypt } from "utils/app.util";
import { createChat, generateRoomId } from "utils/chat.util";
import { db } from "utils/firebase.util";
import {
  InfoSection,
  TeamMembersSection,
  TermsSection,
  UniqueSellingPoints,
} from "./Components";

export const FundsDetailPage = () => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const navigate = useNavigate();

  const {
    workspaceId: fundWorkspaceId,
    displayName: fundsDisplayName,
    logoUrl,
  } = useAppSelector(selectSelectedFundOnFeed);
  const { id: workspaceId, displayName: workspaceName } = useAppSelector(
    selectActiveWorkspace
  );

  const handleReachOut = async () => {
    try {
      if (workspaceId && fundWorkspaceId) {
        //Handle only send message in first time
        const roomId = generateRoomId(workspaceId, fundWorkspaceId);
        const roomRef = doc(db, "rooms", roomId);
        const roomDoc = await getDoc(roomRef);
        if (roomDoc.exists()) {
          navigate(`/chats/${roomId}`);
          return;
        }
      }

      await createChat({
        user1: {
          id: workspaceId,
          displayName: workspaceName,
          type: "investor",
        },
        user2: {
          id: fundWorkspaceId,
          displayName: fundsDisplayName,
          avatar: logoUrl,
          type: "founder",
        },
        handleRoomId: async (roomId) => {
          const messageRef = collection(db, "messages");
          const notificationRef = collection(db, "notifications");
          const roomRef = doc(db, "rooms", roomId);

          const roomDoc = await getDoc(roomRef);

          if (roomDoc.exists()) {
            const newMessage = {
              message: encrypt(
                `${MESSAGE_CODE.REACH_OUT_FUNDS}__${fundWorkspaceId}__Reach out to ${fundsDisplayName} fund!`
              ),
              roomId,
              senderId: workspaceId,
              createdAt: new Date().valueOf(),
              isRead: false,
            };

            const unread = roomDoc.data().unread;
            await updateDoc(roomRef, {
              updatedAt: new Date().valueOf(),
              unread: {
                ...unread,
                [fundWorkspaceId]: unread[fundWorkspaceId] + 1,
              },
              lastMessage: newMessage,
            });
            await addDoc(messageRef, newMessage);
            await addDoc(notificationRef, {
              code: `${NOTI_CODE.CHAT_REACH_OUT_FUNDS}__${workspaceId}__${roomId}`,
              receiverId: fundWorkspaceId,
              createdAt: new Date().valueOf(),
              isRead: false,
              unreadCount: 1,
            });

            navigate(`/chats/${roomId}`);
          }
        },
        handleLoading: (loading) => setConfirmLoading(loading),
      });

      // eslint-disable-next-line no-empty
    } catch (_) {}
  };

  return (
    <Wrapper>
      <LeftBox className="main">
        <HeaderBox>
          <LeftBox>
            <InfoSection handleReachOut={() => setOpen(true)} />
          </LeftBox>

          <RightBox className="header-right">
            <TeamMembersSection />
          </RightBox>
        </HeaderBox>

        <FooterBox>
          <TermsSection />
        </FooterBox>
      </LeftBox>

      <RightBox>
        <UniqueSellingPoints handleReachOut={() => setOpen(true)} />
      </RightBox>

      <ConfirmModal
        title="Reach out to this fund"
        subTitle="Send a message to the fund manager, get to know them, ask for additional information, get involved!"
        isOpen={open}
        onClose={() => setOpen(false)}
        callback={handleReachOut}
        loading={confirmLoading}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  height: calc(100vh - 64px);
  padding: 20px;

  .section-wrapper {
    padding-top: 15px;
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    flex-direction: column;
  }

  @media (max-width: 1640px) and (min-width: 1400px) {
    .section-wrapper__title {
      font-size: 18px;
    }
  }
`;

const LeftBox = styled.div`
  flex: 1;

  & > .section-wrapper {
    padding-top: 15px;
  }

  &.main {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;

const RightBox = styled.div`
  flex-shrink: 0;
  width: 320px;

  &.header-right {
    width: 420px;
  }

  & > div {
    height: 100%;
  }

  @media (max-width: 1400px) {
    & > div {
      height: unset;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

const HeaderBox = styled.div`
  flex: 1;
  display: flex;
  gap: 12px;
  height: calc(100% - 256px);

  & > div > div {
    height: 100%;
  }

  @media (max-width: 1400px) {
    height: unset;
    flex-direction: column;

    .header-right {
      width: 100%;
    }
  }
`;

const FooterBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
