import { City } from "country-state-city";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";

import commonApi from "api/common.api";
import {
  OrganisationDropdown,
  OrganisationFieldInput,
  SectionWrapper,
} from "components";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { Option } from "models/common.model";
import { Country } from "models/prospect.model";
import { selectOrganisation } from "store/organisation/organisation.selector";
import { selectCountries } from "store/cached/cached.selector";
import { cachedActions } from "store/cached/cached.slice";

export const LocationSection = () => {
  const [cities, setCities] = useState<Option[]>([]);

  const dispatch = useAppDispatch();

  const fixedData = useAppSelector(selectCountries);
  const { country } = useAppSelector(selectOrganisation);

  const stringSchema = yup.string().nullable();

  const countryOptions = fixedData.data.map((country: Country) => ({
    ...country,
    label: country.name,
  }));

  useEffect(() => {
    dispatch(
      cachedActions.getFixedData({
        resName: "countries",
        callApi: commonApi.countriesGet,
        updateAt: fixedData.updateAt,
      })
    );
  }, [dispatch, fixedData.updateAt]);

  const getListCity = useCallback(async () => {
    if (country?.alpha2Code) {
      const res = City.getCitiesOfCountry(country.alpha2Code);

      const citiesData = res?.map((city) => ({
        label: city.name,
        name: city.name,
        id: `${city.name}-${city.stateCode}`,
      }));

      setCities(citiesData || []);
    }
  }, [country?.alpha2Code]);

  useEffect(() => {
    getListCity();
  }, [getListCity]);

  return (
    <SectionWrapper title="Location">
      <Container>
        <OrganisationDropdown
          fieldName="Country"
          name="country"
          placeholder="Fill country"
          popperPlacement="top"
          freeSolo={false}
          options={countryOptions}
        />

        <OrganisationDropdown
          fieldName="City"
          name="city"
          placeholder="Fill city"
          popperPlacement="top"
          freeSolo={false}
          options={cities}
        />

        <OrganisationFieldInput
          fieldName="ZIP Code"
          placeholder="Fill zip code"
          name="zip"
          yupSchema={stringSchema}
        />

        <OrganisationFieldInput
          fieldName="Registered office address"
          placeholder="Fill registered office address"
          name="address"
          yupSchema={stringSchema}
        />
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
