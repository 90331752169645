import { yupResolver } from "@hookform/resolvers/yup";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";

import GroupTextField from "components/UI/Form/Input/GroupTextField";
import { TextArea } from "components/UI/Form/Input/TextArea";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { AUTH_MODE_TYPE } from "models/auth.model";
import { Organisation } from "models/organisation.model";
import { selectAuthMode } from "store/auth/auth.selector";
import {
  selectOrganisation,
  selectPossibleEditOrganisation,
} from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";
import { db } from "utils/firebase.util";

interface Props {
  fieldType?: "text" | "text-area" | "number";
  fieldName: string;
  name: string;
  yupSchema: yup.AnySchema;
  placeholder?: string;
  maxLength?: number;
  tooltipText?: string;
}

export const OrganisationFieldInput = ({
  fieldType = "text",
  fieldName,
  name,
  yupSchema,
  placeholder,
  maxLength = 140,
  tooltipText,
}: Props) => {
  const dispatch = useAppDispatch();
  const organisation = useAppSelector(selectOrganisation);
  const possibleEditOrganisation = useAppSelector(
    selectPossibleEditOrganisation
  );
  const authMode = useAppSelector(selectAuthMode);
  const isAdmin = authMode === AUTH_MODE_TYPE.ADMIN;

  const fieldData = organisation[name as keyof Organisation];

  const schema = yup.object().shape({ [name]: yupSchema });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (fieldData === -1 && fieldType === "number") {
      setValue(name, null);
    } else {
      setValue(name, fieldData);
    }
  }, [fieldData, fieldType, name, setValue]);

  const handleUpdateField = (data: FieldValues) => {
    const value = data?.[name];
    const input = {
      [name]: value,
    };

    if (value === fieldData) return;

    if (value === null && fieldType === "number") {
      input[name] = -1;
    }

    const callback =
      name === "displayName"
        ? async () => {
            const docRef = doc(db, "users", organisation.id);
            const docData = await getDoc(docRef);

            if (docData.exists()) {
              await updateDoc(docRef, {
                displayName: value,
              });
            }
          }
        : undefined;

    dispatch(
      organisationActions.updateOrganisation({
        organisationId: organisation.id,
        input: input,
        callback,
      })
    );
  };

  if (fieldType === "number")
    return (
      <GroupTextField
        fieldName={fieldName}
        name={name}
        placeholder={placeholder || "Please fill data"}
        type="number"
        tooltipText={tooltipText}
        formRef={register}
        error={errors?.[name]?.message as string}
        onBlur={handleSubmit(handleUpdateField)}
        hasClear
        watch={watch}
        reset={reset}
        trigger={trigger}
        afterClear={() => handleSubmit(handleUpdateField)()}
        disabled={isAdmin || !possibleEditOrganisation}
      />
    );

  if (fieldType === "text")
    return (
      <GroupTextField
        fieldName={fieldName}
        name={name}
        placeholder={placeholder || "Please fill data"}
        type="input"
        tooltipText={tooltipText}
        formRef={register}
        error={errors?.[name]?.message as string}
        onBlur={handleSubmit(handleUpdateField)}
        hasClear
        watch={watch}
        reset={reset}
        trigger={trigger}
        afterClear={() => handleSubmit(handleUpdateField)()}
        disabled={isAdmin || !possibleEditOrganisation}
      />
    );

  if (fieldType === "text-area")
    return (
      <TextArea
        fieldName={fieldName}
        name={name}
        placeholder={placeholder || "Type some words here..."}
        minRows={3}
        maxLength={maxLength}
        hasCount
        formRef={register}
        watch={watch}
        error={errors?.[name]?.message as string}
        onBlur={handleSubmit(handleUpdateField)}
        hasClear
        reset={reset}
        trigger={trigger}
        afterClear={() => handleSubmit(handleUpdateField)()}
        disabled={isAdmin || !possibleEditOrganisation}
      />
    );

  return null;
};
