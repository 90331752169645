import { Unsubscribe } from "firebase/auth";
import {
  DocumentData,
  QuerySnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  where,
} from "firebase/firestore";

import {
  LIMIT_MESSAGE_FETCHING,
  LIMIT_NOTIFICATION_FETCHING,
  LIMIT_ROOM_FETCHING,
} from "constants/app.const";
import { Chat, Message, Notification, UserType } from "models/chat.model";
import { db } from "./firebase.util";

interface UserParam {
  id: string;
  displayName?: string;
  avatar?: string;
  type: UserType;
}
interface CreateChatParams {
  user1: UserParam;
  user2: UserParam;
  handleLoading?: (loading: boolean) => void;
  handleRoomId?: (roomId: string) => void;
}

export const generateRoomId = (userId1: string, userId2: string) => {
  const sortedParams = [userId1, userId2].sort();

  const combinedString = sortedParams.join("-");

  let hash = 0;
  for (let i = 0; i < combinedString.length; i++) {
    const char = combinedString.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  const positiveId = Math.abs(hash).toString();
  const paddedId = positiveId.padStart(16, "0");

  return paddedId;
};

export const createChat = async ({
  user1,
  user2,
  handleLoading,
  handleRoomId,
}: CreateChatParams) => {
  try {
    handleLoading && handleLoading(true);

    const roomId = generateRoomId(user1.id, user2.id);
    const roomRef = doc(db, "rooms", roomId);

    const roomDoc = await getDoc(roomRef);

    if (roomDoc.exists()) {
      handleLoading && handleLoading(false);
      handleRoomId && handleRoomId(roomDoc.id);
    } else {
      await setDoc(roomRef, {
        memberIds: [user1.id, user2.id],
        updatedAt: new Date().valueOf(),
        onChat: [],
        unread: { [user1.id]: 0, [user2.id]: 0 },
        lastMessage: null,
      });

      const user1Ref = doc(db, "users", user1.id);
      const user1Doc = await getDoc(user1Ref);
      if (!user1Doc.exists()) {
        const { displayName = null, avatar = null, type } = user1;

        await setDoc(user1Ref, { displayName, avatar, type, isOnline: false });
      }

      const user2Ref = doc(db, "users", user2.id);
      const user2Doc = await getDoc(user2Ref);
      if (!user2Doc.exists()) {
        const { displayName = null, avatar = null, type } = user2;

        await setDoc(user2Ref, { displayName, avatar, type, isOnline: false });
      }

      handleLoading && handleLoading(false);
      handleRoomId && handleRoomId(roomId);
    }
  } catch (err) {
    handleLoading && handleLoading(false);
  }
};

export const getFirstMessageBatch = (
  roomId: string,
  snapshotCallback: (querySnapshot: QuerySnapshot<DocumentData>) => void
): Unsubscribe => {
  const q = query(
    collection(db, "messages"),
    where("roomId", "==", roomId),
    orderBy("createdAt", "desc"),
    limit(LIMIT_MESSAGE_FETCHING)
  );

  return onSnapshot(q, snapshotCallback);
};

export const messagesNextBatch = async (roomId: string, lastDocument: any) => {
  const next = query(
    collection(db, "messages"),
    where("roomId", "==", roomId),
    orderBy("createdAt", "desc"),
    startAfter(lastDocument),
    limit(LIMIT_MESSAGE_FETCHING)
  );

  const messages: Message[] = [];

  const documentSnapshots = await getDocs(next);

  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];

  documentSnapshots.forEach((doc) => {
    messages.push({ id: doc.id, ...doc.data() } as Message);
  });

  return { messages, lastVisible };
};

export const getFirstRoomBatch = (
  userId: string,
  snapshotCallback: (querySnapshot: QuerySnapshot<DocumentData>) => void
): Unsubscribe => {
  const q = query(
    collection(db, "rooms"),
    where("memberIds", "array-contains", userId),
    orderBy("updatedAt", "desc"),
    limit(LIMIT_ROOM_FETCHING)
  );

  return onSnapshot(q, snapshotCallback);
};

export const roomsNextBatch = async (userId: string, lastDocument: any) => {
  const next = query(
    collection(db, "rooms"),
    where("memberIds", "array-contains", userId),
    orderBy("updatedAt", "desc"),
    startAfter(lastDocument),
    limit(LIMIT_ROOM_FETCHING)
  );

  const rooms: Chat[] = [];

  const documentSnapshots = await getDocs(next);

  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];

  documentSnapshots.forEach((doc) => {
    rooms.push({ id: doc.id, ...doc.data() } as Chat);
  });

  return { rooms, lastVisible };
};

export const getFirstNotificationBatch = (
  userId: string,
  snapshotCallback: (querySnapshot: QuerySnapshot<DocumentData>) => void
): Unsubscribe => {
  const q = query(
    collection(db, "notifications"),
    where("receiverId", "==", userId),
    orderBy("createdAt", "desc"),
    limit(LIMIT_NOTIFICATION_FETCHING)
  );

  return onSnapshot(q, snapshotCallback);
};

export const notificationNextBatch = async (
  userId: string,
  lastDocument: any
) => {
  const next = query(
    collection(db, "notifications"),
    where("receiverId", "==", userId),
    orderBy("createdAt", "desc"),
    startAfter(lastDocument),
    limit(LIMIT_NOTIFICATION_FETCHING)
  );

  const notifications: Notification[] = [];

  const documentSnapshots = await getDocs(next);

  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];

  documentSnapshots.forEach((doc) => {
    notifications.push({ id: doc.id, ...doc.data() } as Notification);
  });

  return { notifications, lastVisible };
};
