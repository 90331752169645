import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import styled from "styled-components";

import { DatePicker, Text } from "components";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { Fundraising } from "models/fundraising.model";
import {
  selectFundraising,
  selectPossibleEditFundraising,
} from "store/fundraising/fundraising.selector";
import { fundraisingActions } from "store/fundraising/fundraising.slice";
import { selectAuthMode } from "store/auth/auth.selector";
import { AUTH_MODE_TYPE } from "models/auth.model";

interface Props {
  fieldName: string;
  name: string;
  placeholder?: string;
}

export const FundraisingDatePicker = ({
  fieldName,
  name,
  placeholder = "Fill date",
}: Props) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);

  const dispatch = useAppDispatch();
  const selectedFundraising = useAppSelector(selectFundraising);
  const isPossibleEditFundraising = useAppSelector(
    selectPossibleEditFundraising
  );
  const authMode = useAppSelector(selectAuthMode);
  const isAdmin = authMode === AUTH_MODE_TYPE.ADMIN;

  const fieldData = selectedFundraising[name as keyof Fundraising];

  const handleUpdateField = async (data: FieldValues) => {
    if (data?.[name] === dayjs(fieldData as number).valueOf()) return;

    try {
      if (selectedFundraising.id) {
        {
          dispatch(
            fundraisingActions.updateFundraising({
              id: selectedFundraising.id,
              input: data,
            })
          );
        }
      }
    } catch (err) {
      //throw err as Error;
    }
  };

  useEffect(() => {
    if (fieldData) {
      setSelectedDate(dayjs(fieldData as number));
    } else {
      setSelectedDate(null);
    }
  }, [fieldData]);

  return (
    <FieldGroup>
      <Text size="md" fontWeight={600}>
        {fieldName}
      </Text>

      <DatePicker
        value={selectedDate}
        onchange={(value) => {
          setSelectedDate(value);
          handleUpdateField({
            [name]: dayjs(value as Dayjs).valueOf(),
          });
        }}
        placeholder={placeholder}
        disabled={isAdmin || !isPossibleEditFundraising}
      />
    </FieldGroup>
  );
};

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  .label {
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;
