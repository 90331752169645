import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";

import { PhoneNumberField } from "components/UI/Form/Input/PhoneNumberField";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { SelectItemTask } from "models/common.model";
import { Fundraising } from "models/fundraising.model";
import {
  selectFundraising,
  selectPossibleEditFundraising,
} from "store/fundraising/fundraising.selector";
import { fundraisingActions } from "store/fundraising/fundraising.slice";
import { selectAuthMode } from "store/auth/auth.selector";
import { AUTH_MODE_TYPE } from "models/auth.model";

interface Props {
  fieldName: string;
  name: string;
  yupSchema: yup.AnySchema;
  placeholder?: string;
}

export const FundraisingPhoneNumberField = ({
  fieldName,
  name,
  yupSchema,
  placeholder,
}: Props) => {
  const dispatch = useAppDispatch();
  const selectedFundraising = useAppSelector(selectFundraising);
  const isPossibleEditFundraising = useAppSelector(
    selectPossibleEditFundraising
  );
  const authMode = useAppSelector(selectAuthMode);
  const isAdmin = authMode === AUTH_MODE_TYPE.ADMIN;

  const fieldData = selectedFundraising[name as keyof Fundraising];
  const schema = yup.object().shape({ [name]: yupSchema });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleUpdateField = async (
    data: FieldValues | { [key: string]: SelectItemTask[] }
  ) => {
    if (data?.[name] === fieldData) return;

    try {
      if (selectedFundraising.id) {
        dispatch(
          fundraisingActions.updateFundraising({
            id: selectedFundraising.id,
            input: {
              [name]: data?.[name] || "",
            },
          })
        );
      }
    } catch (err) {
      //throw err as Error;
    }
  };

  useEffect(() => {
    setValue(name, fieldData);
  }, [fieldData, name, setValue]);

  return (
    <PhoneNumberField
      fieldName={fieldName}
      name={name}
      placeholder={placeholder || "Please fill phone number"}
      control={control}
      error={errors?.[name]?.message as string}
      onBlur={handleSubmit(handleUpdateField)}
      disabled={isAdmin || !isPossibleEditFundraising}
    />
  );
};
