import {
  Badge,
  IconButton,
  Toolbar as ToolbarMui,
  Tooltip,
} from "@mui/material";
import { BellIcon, GalleryIcon } from "assets/images/common";
import { ProfileIcon } from "assets/images/common/ProfileIcon";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { AUTH_MODE_TYPE } from "models/auth.model";
import { useRef, useState } from "react";
import { selectAuthMode } from "store/auth/auth.selector";
import { authActions } from "store/auth/auth.slice";
import { selectNotificationList } from "store/chat/chat.selector";
import styled from "styled-components/macro";
import { Notification } from "./Notification";
import ProfilePanel from "./ProfilePanel";

const TopBarTool = () => {
  const dispatch = useAppDispatch();
  const authMode = useAppSelector(selectAuthMode);
  const [openProfilePanel, setOpenProfilePanel] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);

  const settingsRef = useRef(null);
  const notificationRef = useRef(null);
  const notifications = useAppSelector(selectNotificationList);
  const unreadCount = notifications
    .filter((i) => !i.isRead)
    .reduce((acc, cur) => acc + cur.unreadCount, 0);

  return (
    <Wrapper>
      <ToolBar>
        {authMode === AUTH_MODE_TYPE.WORKSPACE && (
          <Tooltip title="My spaces">
            <IconButton onClick={() => dispatch(authActions.workspaceLogout())}>
              <GalleryIcon />
            </IconButton>
          </Tooltip>
        )}

        {authMode !== AUTH_MODE_TYPE.USER && (
          <IconButton
            ref={notificationRef}
            onClick={() => setOpenNotification(true)}
          >
            <Badge badgeContent={unreadCount} color="error">
              <BellIcon />
            </Badge>
          </IconButton>
        )}

        <IconButton
          onClick={() => setOpenProfilePanel(!openProfilePanel)}
          ref={settingsRef}
        >
          <ProfileIcon />
        </IconButton>

        {authMode !== AUTH_MODE_TYPE.USER && (
          <Notification
            anchorEl={notificationRef.current}
            open={openNotification}
            onClose={() => setOpenNotification(false)}
          />
        )}

        <ProfilePanel
          anchorEl={settingsRef.current}
          onClose={() => {
            setOpenProfilePanel(!openProfilePanel);
          }}
          open={openProfilePanel}
        />
      </ToolBar>
    </Wrapper>
  );
};

export default TopBarTool;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ToolBar = styled(ToolbarMui)`
  min-height: 64px;
  gap: 10px;

  button {
    width: 35px;
    height: 35px;
  }
`;
