import { PopperProps } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";

import { Combobox } from "components/Feature/Combobox";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { AUTH_MODE_TYPE } from "models/auth.model";
import { Option } from "models/common.model";
import { Organisation, SelectType } from "models/organisation.model";
import { selectAuthMode } from "store/auth/auth.selector";
import {
  selectOrganisation,
  selectPossibleEditOrganisation,
} from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";

interface Props {
  fieldName: string;
  name: string;
  placeholder?: string;
  freeSolo?: boolean;
  popperPlacement?: PopperProps["placement"];
  options: Option[];
}

const stringField = ["city"];

export const OrganisationDropdown = ({
  fieldName,
  name,
  placeholder,
  freeSolo,
  options,
  popperPlacement,
}: Props) => {
  const [selectedItem, setSelectedItem] = useState<SelectType>();
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const organisation = useAppSelector(selectOrganisation);
  const possibleEditOrganisation = useAppSelector(
    selectPossibleEditOrganisation
  );
  const authMode = useAppSelector(selectAuthMode);
  const isAdmin = authMode === AUTH_MODE_TYPE.ADMIN;

  const fieldData = organisation[name as keyof Organisation] as SelectType;
  const isStringField = _.includes(stringField, name);

  const handleUpdateField = (data: FieldValues) => {
    setLoading(true);

    if (isStringField) {
      if (fieldData === data?.[name]) return;
    } else {
      if (fieldData?.id === data?.[name]) return;
    }

    const input = isStringField
      ? data
      : {
          [name]: {
            id: data?.[name],
          },
        };

    if (name === "country") {
      input.city = "";
    }

    dispatch(
      organisationActions.updateOrganisation({
        organisationId: organisation.id,
        input,
        callback: () => {
          setLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    if (fieldData) {
      if (isStringField) {
        setSelectedItem({
          label: fieldData,
          name: fieldData,
        } as any);
      } else {
        setSelectedItem({
          ...fieldData,
          label:
            name === "currency"
              ? `${fieldData.name} (${fieldData.symbol})`
              : fieldData.name,
        });
      }
    } else {
      if (name === "currency") {
        const defaultCurrency = options.find((i) => i.id === "EUR");
        setSelectedItem(defaultCurrency as any);
      } else {
        setSelectedItem(undefined);
      }
    }
  }, [fieldData, isStringField, name, options]);

  return (
    <Combobox
      fieldName={fieldName}
      id={`combobox-${name}`}
      freeSolo={freeSolo}
      options={options}
      placeholder={placeholder}
      disabled={isAdmin || !possibleEditOrganisation}
      loading={loading}
      onchange={(value) => {
        if (!value) return;
        const data = value as SelectType;

        handleUpdateField({
          [name]: isStringField ? data.name : data.id,
        });
      }}
      value={selectedItem || null}
      popperPlacement={popperPlacement}
      isOptionEqualToValue={(option, value) => {
        if (isStringField) {
          return (option as Option).name === (value as SelectType).name;
        } else {
          return (option as Option).id === (value as SelectType).id;
        }
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={(option as Option).id}>
            {(option as Option).label}
          </li>
        );
      }}
    />
  );
};
