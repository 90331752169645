import { call, put, takeLatest } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import workspaceApi from "api/workspace.api";
import { AUTH_MODE_TYPE, WORKSPACE_TYPE } from "models/auth.model";
import { Workspace } from "models/workspace.model";
import { workspaceActions } from "store/workspace/workspace.slice";
import { parseToken } from "utils/system.util";
import {
  AUTH_MODE,
  NEW_WORKSPACES,
  WORKSPACE_ID,
  WORKSPACE_TYPE_KEY,
  WORKSPACE_UPDATE,
} from "../../constants/app.const";
import { LocalStorage } from "../../utils/localStorage.util";
import { authActions } from "./auth.slice";

function* userUpdateRole(action: PayloadAction<AUTH_MODE_TYPE>) {
  try {
    if (action.payload) {
      LocalStorage.set(action.payload, AUTH_MODE);
      yield put(authActions.userLoginSuccess(action.payload));
    }
  } catch (error) {
    yield put(authActions.userLoginFailed());
  }
}

function* userLogin(action: PayloadAction<string>) {
  try {
    if (action.payload) {
      const tokenObject = parseToken(action.payload);
      const { role } = tokenObject;
      const userRole =
        role && role?.includes("admin")
          ? AUTH_MODE_TYPE.ADMIN
          : AUTH_MODE_TYPE.USER;
      LocalStorage.set(userRole, AUTH_MODE);
      yield put(authActions.userLoginSuccess(userRole));
    }
  } catch (error) {
    yield put(authActions.userLoginFailed());
  }
}

function* userLogout() {
  try {
    yield put(authActions.userLogoutSuccess());
    LocalStorage.remove(AUTH_MODE);
    LocalStorage.remove(WORKSPACE_ID);
    //LocalStorage.remove(NAMESPACE_ID);
  } catch (err) {
    //throw err as Error;
  }
}

function* workspaceLogin(
  action: PayloadAction<{
    id: string;
    type?: WORKSPACE_TYPE;
    isUpdate?: boolean;
    callback: (type?: WORKSPACE_TYPE, isUpdate?: boolean) => void;
  }>
) {
  try {
    const { workspaceLogin } = yield call(
      workspaceApi.workspaceLogin,
      action.payload.id
    );
    if (workspaceLogin) {
      action.payload.callback(action.payload.type, action.payload.isUpdate);
      LocalStorage.set(workspaceLogin, WORKSPACE_ID);
      LocalStorage.set(action.payload.type, WORKSPACE_TYPE_KEY);
      LocalStorage.set(action.payload.isUpdate, WORKSPACE_UPDATE);
    }
  } catch (error) {
    yield put(authActions.workspaceLoginFailed());
  }
}

function* workspaceLogout() {
  try {
    const workspaceId = LocalStorage.get(WORKSPACE_ID);
    const newWorkspaces: string[] = LocalStorage.get(NEW_WORKSPACES) || [];
    const filteredWorkspaces = newWorkspaces.filter((i) => i !== workspaceId);
    if (filteredWorkspaces.length) {
      LocalStorage.set(filteredWorkspaces, NEW_WORKSPACES);
    } else {
      LocalStorage.remove(NEW_WORKSPACES);
    }

    LocalStorage.set(AUTH_MODE_TYPE.USER, AUTH_MODE);
    LocalStorage.remove(WORKSPACE_ID);
    LocalStorage.remove(WORKSPACE_TYPE_KEY);

    yield put(workspaceActions.getWorkspaceSuccess({} as Workspace));
    yield put(authActions.workspaceLogoutSuccess());
  } catch (err) {
    //throw err as Error;
  }
}

export default function* authSaga() {
  yield takeLatest(authActions.workspaceLogin.type, workspaceLogin);
  yield takeLatest(authActions.workspaceLogout, workspaceLogout);
  yield takeLatest(authActions.userLogin.type, userLogin);
  yield takeLatest(authActions.userUpdateRole.type, userUpdateRole);
  yield takeLatest(authActions.userLogout.type, userLogout);
}
