import {
  deleteObject,
  getDownloadURL,
  getStorage,
  listAll,
  ref,
  uploadBytes,
} from "@firebase/storage";
import {
  AUTH_MODE,
  FIREBASE_CONFIG,
  LIMIT_TIME_AUTHEN,
} from "constants/app.const";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { parseToken } from "./system.util";

export const getFirebaseToken = async () => {
  const currentUser = getAuth().currentUser;
  if (currentUser) return currentUser.getIdToken(true);

  const hasRememberedAccount = localStorage.getItem(AUTH_MODE);
  if (!hasRememberedAccount) return null;

  return new Promise((resolve, reject) => {
    const waitTimer = setTimeout(() => {
      reject(null);
    }, LIMIT_TIME_AUTHEN);

    const unregisterAuthObserver = onAuthStateChanged(
      getAuth(),
      async (user) => {
        if (!user) {
          reject(null);
        }

        const token = await user?.getIdToken(true);
        resolve(token);

        unregisterAuthObserver();
        clearTimeout(waitTimer);
      }
    );
  });
};

export const getFirebaseCurrentUser = async () => {
  const currentUser = getAuth().currentUser;
  if (currentUser) return currentUser;

  return new Promise((resolve, reject) => {
    const waitTimer = setTimeout(() => {
      reject(null);
    }, LIMIT_TIME_AUTHEN);

    const unregisterAuthObserver = onAuthStateChanged(
      getAuth(),
      async (user) => {
        if (!user) {
          reject(null);
        }
        resolve(user);

        unregisterAuthObserver();
        clearTimeout(waitTimer);
      }
    );
  });
};

export const getFirebaseTokenObject = async () => {
  const token = await getFirebaseToken();
  if (token) return parseToken(token as string);
  return;
};

export const firebaseApp = initializeApp(FIREBASE_CONFIG);

export const firebaseStorage = getStorage(firebaseApp);

export const db = getFirestore(firebaseApp);

export const uploadFromBlobAsync = ({
  blob,
  name,
  folder,
}: {
  blob: any;
  name: string;
  folder?: string;
}) =>
  new Promise((resolve, reject) => {
    try {
      const storageRef = ref(
        firebaseStorage,
        folder ? `${folder}/${name}` : name
      );
      uploadBytes(storageRef, blob).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      });
    } catch (error) {
      reject(error);
    }
  });

export const getFileBlob = (url: string) => {
  return new Promise((resolve, reject) => {
    const storage = getStorage();
    getDownloadURL(ref(storage, url))
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = () => {
          const blob = xhr.response;
          resolve(blob);
        };
        xhr.open("GET", url);
        xhr.send();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteFolder = async (folderPath: string) => {
  try {
    const folderRef = ref(firebaseStorage, folderPath);
    const list = await listAll(folderRef);

    list.items.forEach((item) => deleteObject(item));
    list.prefixes.forEach((item) => deleteFolder(item.fullPath));

    // eslint-disable-next-line no-empty
  } catch (_) {}
};
