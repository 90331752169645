import { gql } from "graphql-request";
import { graphqlRequest } from "utils/graphQL.util";

const adminApi = {
  adminStats(query) {
    const defaultQuery = gql`
      query {
        node: statsAdmin
      }
    `;
    return graphqlRequest(query || defaultQuery);
  },
};

export default adminApi;
