import _ from "lodash";

export const getType = function getType(
  str,
  number_of_tokens = 2,
  delimiter = "_"
) {
  const start = 0;
  const tokens = str.split(delimiter).slice(start, number_of_tokens);
  return tokens.join(delimiter);
};

export const loadingTypeAdd = function (
  currentLoadingState,
  type,
  number_of_tokens = 2,
  delimiter = "_"
) {
  return _.union(currentLoadingState, [
    getType(type, number_of_tokens, delimiter),
  ]);
};

export const loadingTypeRemove = function (
  currentLoadingState,
  type,
  number_of_tokens = 2,
  delimiter = "_"
) {
  const nextState = _.filter(
    currentLoadingState,
    (obj) => obj !== getType(type, number_of_tokens, delimiter)
  );
  if (!nextState) {
    return [];
  } else {
    return nextState;
  }
};
