import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import EmptyImage from "assets/images/common/empty.png";
import { Empty, SearchBox } from "components";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { AUTH_MODE_TYPE, WORKSPACE_TYPE } from "models/auth.model";
import { Chat, UserType } from "models/chat.model";
import {
  selectAuthMode,
  selectNamespaceId,
  selectWorkspaceType,
} from "store/auth/auth.selector";
import { selectSelectedChat, selectUserId } from "store/chat/chat.selector";
import { chatActions } from "store/chat/chat.slice";
import { selectOrganisation } from "store/organisation/organisation.selector";
import { selectActiveWorkspace } from "store/workspace/workspace.selector";
import { createChat } from "utils/chat.util";
import { db } from "utils/firebase.util";
import { ChatBox, ChatList } from "./components";

export const Chats = () => {
  const [chatLoading, setChatLoading] = useState(false);

  const { id: roomId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedChat = useAppSelector(selectSelectedChat);
  const organisation = useAppSelector(selectOrganisation);
  const workspace = useAppSelector(selectActiveWorkspace);
  const workspaceType = useAppSelector(selectWorkspaceType);
  const namespaceId = useAppSelector(selectNamespaceId);
  const authMode = useAppSelector(selectAuthMode);
  const userId = useAppSelector(selectUserId);

  const isAdmin = authMode === AUTH_MODE_TYPE.ADMIN;

  const handleChatToAdmin = async () => {
    if (!namespaceId) return;

    let displayName = "";
    let avatar = "";

    if (workspaceType === WORKSPACE_TYPE.FOUNDER) {
      displayName = organisation.displayName;
      avatar = organisation.logoUrl;
    }

    if (workspaceType === WORKSPACE_TYPE.INVESTOR) {
      displayName = workspace.displayName;
    }

    createChat({
      user1: { id: namespaceId, type: "admin" },
      user2: {
        id: userId,
        displayName,
        avatar,
        type: workspaceType as UserType,
      },
      handleLoading: (loading) => setChatLoading(loading),
      handleRoomId: (roomId) => navigate(`/chats/${roomId}`),
    });
  };

  useEffect(() => {
    if (!roomId) return;

    const docRef = doc(db, "rooms", roomId);

    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        dispatch(
          chatActions.setSelectedChat({ ...doc.data(), id: doc.id } as Chat)
        );
      } else {
        navigate("/chats");
      }
    });

    return () => {
      unsubscribe();
      dispatch(chatActions.setSelectedChat(undefined));
    };
  }, [dispatch, navigate, roomId]);

  return (
    <Wrapper>
      <LeftBox>
        {false && (
          <SearchBox
            placeholder="Search"
            autoSearch={true}
            onSearch={(value) => dispatch(chatActions.setSearchText(value))}
          />
        )}

        <ChatList />
      </LeftBox>

      <RightBox>
        {selectedChat ? (
          <ChatBox />
        ) : (
          <Empty
            imageUrl={EmptyImage}
            title="Select a chat to start messaging"
            maxWidth={280}
            hasButton={!isAdmin}
            buttonText="Chat to Admin"
            buttonLoading={chatLoading}
            handleClick={handleChatToAdmin}
          />
        )}
      </RightBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;
`;

const LeftBox = styled.div`
  width: 355px;
  height: 100%;
  padding: 16px;
  padding-bottom: 0px;
  border-right: 1px solid #ebecef;

  .search-box {
    height: 40px;
    margin-bottom: 24px;
  }
`;

const RightBox = styled.div`
  flex: 1;
  height: 100%;
`;
