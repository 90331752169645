import { all } from "redux-saga/effects";

import authSaga from "./auth/auth.saga";
import workspaceSaga from "./workspace/workspace.saga";
import prospectSaga from "./prospect/prospect.saga";
import feedSaga from "./feed/feed.saga";
import organisationSaga from "./organisation/organisation.saga";
import fundraisingSaga from "./fundraising/fundraising.saga";
import reportSaga from "./report/report.saga";
import cachedSaga from "./cached/cached.saga";
import postSaga from "./post/post.saga";
import fundSaga from "./fund/fund.saga";
import adminSaga from "./admin/admin.sagas";

export default function* rootSaga() {
  yield all([
    authSaga(),
    workspaceSaga(),
    prospectSaga(),
    feedSaga(),
    organisationSaga(),
    fundraisingSaga(),
    reportSaga(),
    cachedSaga(),
    postSaga(),
    fundSaga(),
    adminSaga(),
  ]);
}
