import { TablePagination } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  DeleteIcon,
  EditIcon,
  PlusIcon,
  WarningIcon,
} from "assets/images/common";
import { Avatar, BasicTable, Button, PageTitle, Warning } from "components";
import { Combobox } from "components/Feature/Combobox";
import ConfirmModal from "components/Feature/ConfirmModal";
import { PATH } from "constants/app.const";
import { useAppDispatch, useAppParams, useAppSelector } from "hooks/useHook";
import { AUTH_MODE_TYPE } from "models/auth.model";
import { Option, StatusIndicator, TBD } from "models/common.model";
import {
  TeamMember,
  UpdateOrganisationParams,
} from "models/organisation.model";
import { selectAuthMode } from "store/auth/auth.selector";
import {
  selectOrganisation,
  selectOrganisationLoading,
  selectPossibleEditOrganisation,
} from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";
import AddTeamMemberModal from "../components/AddTeamMemberModal";

const CONFIG = [
  {
    name: "Name",
    width: "auto",
  },
  {
    name: "Role",
    width: "auto",
  },
  {
    name: "Email",
    width: "auto",
  },
  {
    name: "Order",
    width: "auto",
  },
  {
    name: "Action",
    width: 240,
  },
];

export const CompanyTeam = () => {
  const [tableData, setTableData] = useState<TBD<unknown>[]>([]);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [member, setMember] = useState<TeamMember | null>(null);
  const [deleteMemberPayload, setDeleteMemberPayload] = useState<
    { memberId: string; currentIndex: number } | undefined
  >();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id: organisationParamsId } = useAppParams();
  const { teamMembers, id, message, status } =
    useAppSelector(selectOrganisation);
  const confirmLoading = useAppSelector(selectOrganisationLoading);
  const possibleEditOrganisation = useAppSelector(
    selectPossibleEditOrganisation
  );
  const authMode = useAppSelector(selectAuthMode);
  const isAdmin = authMode === AUTH_MODE_TYPE.ADMIN;

  const isOnlyView = isAdmin || !possibleEditOrganisation;

  const options = useMemo(() => {
    if (!teamMembers) return [];

    return teamMembers.map((_, index) => ({
      label: (index + 1).toString(),
      value: (index + 1).toString(),
    }));
  }, [teamMembers]);

  const handleEditTeamMember = (member: TeamMember) => {
    setMember(member);
    setIsOpenAddModal(true);
  };

  const handleDeleteTeamMember = () => {
    if (deleteMemberPayload) {
      const { memberId, currentIndex } = deleteMemberPayload;

      const reorderTeamMembers: UpdateOrganisationParams["teamMembers"] =
        teamMembers.slice(currentIndex + 1).map((member, index) => ({
          id: member.id,
          data: {
            order: currentIndex + index + 1,
          },
          task: "UPDATE",
        }));
      const data: UpdateOrganisationParams["teamMembers"] = [
        {
          id: memberId,
          task: "DELETE",
        },
        ...reorderTeamMembers,
      ];

      dispatch(
        organisationActions.updateOrganisation({
          organisationId: id,
          input: {
            teamMembers: data,
          },
          callback: () => {
            setDeleteMemberPayload(undefined);
          },
        })
      );
    }
  };

  const handleReorderTeamMembers = useCallback(
    (payload: {
      currentMember: TeamMember;
      currentIndex: number;
      newIndex: number;
    }) => {
      const { currentMember, currentIndex, newIndex } = payload;

      const reorderedTeamMembers = [...teamMembers];
      reorderedTeamMembers.splice(currentIndex, 1);
      reorderedTeamMembers.splice(newIndex, 0, currentMember);

      const data: UpdateOrganisationParams["teamMembers"] =
        reorderedTeamMembers.map((member, index) => ({
          id: member.id,
          data: {
            order: index + 1,
          },
          task: "UPDATE",
        }));

      dispatch(
        organisationActions.reorderTeamMember({
          organisationId: id,
          teamMembers: data,
        })
      );
    },
    [dispatch, id, teamMembers]
  );

  useEffect(() => {
    if (isAdmin && organisationParamsId) {
      dispatch(
        organisationActions.getOrganisation({
          id: organisationParamsId,
          isAdmin,
        })
      );
    } else {
      dispatch(organisationActions.getCompanyOrganisation());
    }
  }, [dispatch, isAdmin, organisationParamsId]);

  useEffect(() => {
    const data = teamMembers.map((member, index) => {
      return {
        name: (
          <GroupCell>
            <Avatar src={member.photoUrl} name={member.displayName} size={24} />

            {member.displayName}
          </GroupCell>
        ),
        role: <GroupCell>{member.role}</GroupCell>,
        email: <GroupCell>{member.email}</GroupCell>,
        order: (
          <GroupCell>
            <Combobox
              id={`combobox-order-${member.id}`}
              key={member.id}
              freeSolo={false}
              options={options}
              onchange={(value) => {
                handleReorderTeamMembers({
                  currentMember: member,
                  currentIndex: index,
                  newIndex: Number((value as Option).value) - 1,
                });
              }}
              value={
                (member.order && member.order.toString()) ||
                (index + 1).toString()
              }
              isOptionEqualToValue={(option, value) => {
                return (option as Option).value === value;
              }}
              disabled={isOnlyView}
            />
          </GroupCell>
        ),
        action: (
          <GroupCell>
            <ActionWrapper onClick={() => handleEditTeamMember(member)}>
              <EditIcon /> {isOnlyView ? "View" : "Edit"}
            </ActionWrapper>

            {!isOnlyView && (
              <ActionWrapper
                onClick={() =>
                  setDeleteMemberPayload({
                    memberId: member.id,
                    currentIndex: index,
                  })
                }
              >
                <DeleteIcon /> Delete
              </ActionWrapper>
            )}
          </GroupCell>
        ),
      };
    });

    setTableData(data);
  }, [handleReorderTeamMembers, isOnlyView, options, teamMembers]);

  return (
    <Wrapper>
      <Header>
        <PageTitle>Team</PageTitle>

        {!isOnlyView && (
          <Button
            className="add-btn"
            icon={<PlusIcon color="#FFFFFF" />}
            onClick={() => setIsOpenAddModal(true)}
          >
            Add member
          </Button>
        )}
      </Header>

      {status === StatusIndicator.FLAGGED && !isAdmin && (
        <Warning
          icon={<WarningIcon />}
          text={`Your Company request was rejected by the moderator.${
            message ? ` "${message}"` : ""
          }`}
          backgroundColor="#fff1f0"
          color="#b4453d"
          hasButton
          buttonText="Complete Settings"
          handleClickButton={() => navigate(PATH.COMPANY_SETTINGS)}
        />
      )}

      <BasicTable title="Manage team member" config={CONFIG} data={tableData} />

      {teamMembers.length > rowsPerPage && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={teamMembers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(Number(e.target.value));
            setPage(0);
          }}
        />
      )}

      <AddTeamMemberModal
        data={member}
        organisationId={id}
        isOpen={isOpenAddModal}
        onClose={() => {
          setIsOpenAddModal(false);
          setMember(null);
        }}
        newMemberOrder={teamMembers.length + 1}
        hideFooter={isOnlyView}
        titleType={isOnlyView ? "View" : member ? "Update" : "Add"}
        isOnlyView={isOnlyView}
      />

      <ConfirmModal
        title="Are you sure to delete member?"
        isOpen={!!deleteMemberPayload}
        onClose={() => setDeleteMemberPayload(undefined)}
        callback={handleDeleteTeamMember}
        loading={confirmLoading}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-height: 100%;
  padding: 32px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }

  & > .MuiPaper-root {
    flex: 1;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  .add-btn {
    height: 40px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const GroupCell = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${(props) => props.theme.palette.primaryText.ligth};
  font-size: 16px;

  .combobox-wrapper {
    width: 120px;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  svg {
    width: 20px;
    height: auto;
  }
  &:hover {
    text-decoration: underline;
  }
`;
