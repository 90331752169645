/** Global config */
export const DATE_TIME_FORMAT = "DD/MM/YYYY H:mm";
export const DATE_FORMAT = "DD/MM/YYYY";

/** LocalStore keys */
export const USER_TOKEN = "USER_TOKEN";
export const AUTH_MODE = "AUTH_MODE";
export const NAMESPACE_ID = "NAMESPACE_ID";
export const WORKSPACE_TYPE_KEY = "WORKSPACE_TYPE_KEY";
export const WORKSPACE_UPDATE = "WORKSPACE_UPDATE";
export const NEW_WORKSPACES = "NEW_WORKSPACES";
export const WORKSPACE_ID = "WORKSPACE_ID";
export const LANGUAGE = "LANGUAGE";
export const APP_CONFIG = "APP_CONFIG";

export const SECRET_KEY = "MONOCO-CRYPTOJS-SECRET-KEY-01072023";

/** Enviroment */
export const ENV = {
  GRAPHQL_URI: process.env.REACT_APP_GRAPHQL_URI,
  GRAPHQL_KEY: process.env.REACT_APP_APP_KEY,
  AUTH_KEY: process.env.REACT_APP_FB_API_KEY,
  AUTH_DOMAIN: process.env.REACT_APP_FB_AUTH_DOMAIN,
  PROJECT_ID: process.env.REACT_APP_FB_PROJECT_ID,
  STORAGE_BUCKET: process.env.REACT_APP_FB_STORAGE_BUCKET,
  MESSAGING_SENDER_ID: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  APP_ID: process.env.REACT_APP_FB_APP_ID,
  MEASUREMENT_ID: process.env.REACT_APP_FB_MEASUREMENT_ID,
  GOOGLE_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  APP_CONFIG: process.env.REACT_APP_CONFIG,
};

/**Router */
export const PATH = {
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  WORKSPACES: "workspaces",
  DASHBOARD: "/deal-flow",
  ADMIN_VIEW: "/admin-view",
  COMPANY_PREVIEW: "/company/preview",
  COMPANY_SETTINGS: "/company/settings",
  COMPANY_TEAM: "/company/team",
  COMPANY_MF_SCORE: "/company/mf-score",
  COMPANY_FUNDRAISING: "/company/fundraising",
  COMPANY_FUNDS: "/company/funds",
  ADMIN_COMPANY: "/admin/company",
  ADMIN_FUNDRAISING: "/admin/fundraising",
};

/** FirebaseConfig */
export const FIREBASE_CONFIG = {
  apiKey: ENV.AUTH_KEY,
  authDomain: ENV.AUTH_DOMAIN,
  projectId: ENV.PROJECT_ID,
  storageBucket: ENV.STORAGE_BUCKET,
  messagingSenderId: ENV.MESSAGING_SENDER_ID,
  appId: ENV.APP_ID,
  measurementId: ENV.MEASUREMENT_ID,
};

/** TIME LIMIT */
export const LIMIT_TIME_AUTHEN = 10000;
export const LIMIT_FIXED_DATA_FETCHING = 4 * 60 * 60 * 1000; // 4 hours

export const SUPPER_ADMIN_ROLE = ["admin", "owner"];
export const MB = 1024 * 1024;

export const ADMIN_ID = "super-admin";
export const LIMIT_ROOM_FETCHING = 20;
export const LIMIT_MESSAGE_FETCHING = 50;
export const LIMIT_NOTIFICATION_FETCHING = 20;

/** Roles */
export enum Roles {
  admin = "admin",
  owner = "owner",
  editor = "editor",
  user = "user",
}

/** Chart */
export const CHART_BACKGROUND_COLOR = [
  "#373B59",
  "#6A6D84",
  "#A5A7B4",
  "#D8D9DF",
  "#ECE2D1",
  "#D4BF99",
];

export const defaultTheme = {
  primary: {
    main: "#ee2d28",
    light: "#DA3C26",
    dark: "#c01e16",
  },
  secondary: {
    main: "#202020",
    light: "#6A6D84",
    dark: "#202020",
  },
  error: {
    main: "#D25C54",
  },
  success: {
    main: "#3B864C",
  },
  primaryText: {
    main: "#021927",
    light: "#6A6D84",
    dark: "#050A30",
  },
};

export const defaultAppConfig = {
  projectId: "wl-mark-challenge",
  id: "e090293d-691a-4440-adc1-8c71b8434c65",
  bannerLogo: "banner-logo.png",
  headerLogo: "header-logo.jpg",
  avatarLogo: "avatar-logo.jpg",
  brandName: " ",
  brandUrl: "https://www.themarkchallenge.com/",
  brandLabel: "www.themarkchallenge.com",
  title: "Registration will be open from January 16th to March 3rd, 2024",
  description:
    "Mark Challenge is a business plan competition powered by the International University of Monaco (IUM) that connects students, alumni, academic professionals, and business leaders from all over the world.",
  themeConfig: {
    primary: {
      main: "#ee2d28",
      light: "#DA3C26",
      dark: "#c01e16",
    },
    secondary: {
      main: "#202020",
      light: "#6A6D84",
      dark: "#202020",
    },
    error: {
      main: "#D25C54",
    },
    success: {
      main: "#3B864C",
    },
    primaryText: {
      main: "#021927",
      light: "#6A6D84",
      dark: "#050A30",
    },
  },
  updateFeed: {
    active: true,
  },
  lockMFT: {
    active: false,
  },
  namespaceFilter: {
    active: false,
    value: "Own",
  },
  feedPromote: {
    active: false,
    value: "https://stripe.com/payments/checkout",
  },
  menuTab: [
    {
      active: true,
      value: "/admin-view",
    },
    {
      active: true,
      value: "/funds",
    },
  ],
};
