import { SECRET_KEY } from "constants/app.const";
import CryptoJS from "crypto-js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { NamespaceState } from "models/common.model";

import { globalActions } from "store/global/global.slice";
import { getFirebaseTokenObject } from "./firebase.util";

export const successNotification = (message: string) =>
  globalActions.pushNotification({
    type: "success",
    message,
  });

export const errorNotification = (message: string) =>
  globalActions.pushNotification({
    type: "error",
    message,
  });

export const warningNotification = (message: string) =>
  globalActions.pushNotification({
    type: "warning",
    message,
  });

export const scrollLoadMore = (
  event: Event | undefined,
  action: () => void
) => {
  if (!event) return;

  const target = event.target as HTMLElement; // Ensure target has HTMLElement type

  // Calculate the scroll height of the scrolling element
  const scrollHeight = target.scrollHeight;

  // Calculate the client height of the scrolling element
  const clientHeight = target.clientHeight;

  // Calculate the current scroll position
  const scrollTop = target.scrollTop;

  // Check if the current scroll position is near or at the bottom of the page
  if (scrollTop + clientHeight >= scrollHeight) {
    action && action();
  }
};

export const delayAction = (delayTime: number, action: () => void) => {
  setTimeout(() => {
    action && action();
  }, delayTime);
};

export const encrypt = (data: any) => {
  const ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    SECRET_KEY
  ).toString();

  return ciphertext;
};

export const decrypt = (ciphertext: string) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  return decryptedData;
};

export const downloadPageAsPDF = async (
  ref: HTMLDivElement,
  fileName: string,
  handleLoading?: (loading: boolean) => void
) => {
  handleLoading && handleLoading(true);

  ref.classList.add("page-as-pdf--processing");

  const pdf = new jsPDF("p", "px", "a4");
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();

  const canvas = await html2canvas(ref);
  const scaleFactor = pdfWidth / canvas.width;
  const scaledCanvasWidth = pdfWidth;
  const scaledCanvasHeight = canvas.height * scaleFactor;

  const imgData = canvas.toDataURL("image/png");
  const imgWidth = pdfWidth;
  const imgHeight = (scaledCanvasHeight * imgWidth) / scaledCanvasWidth;

  let position = 0;
  let currentPage = 1;
  const pageHeight = pdfHeight;

  while (position > -scaledCanvasHeight) {
    if (currentPage !== 1) {
      pdf.addPage();
    }

    pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);

    position -= pageHeight;
    currentPage++;
  }

  ref.classList.remove("page-as-pdf--processing");

  handleLoading && handleLoading(false);
  pdf.save(fileName);
};

export const getNamespaceId = async () => {
  const tokenObject = await getFirebaseTokenObject();
  if (tokenObject) {
    const namespaceId = tokenObject?.ws_ns || tokenObject?.ns;
    if (namespaceId) return namespaceId;
  }
  return;
};

export const getNamespaceToggle = (value: NamespaceState): NamespaceState => {
  if (value === "Global") return "Own";
  return "Global";
};
