import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import _ from "lodash";
import { RefObject, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";

import { WarningIcon } from "assets/images/common";
import { PageTitle, ScrollToWrapper, Warning } from "components";
import ConfirmModal from "components/Feature/ConfirmModal";
import { PATH } from "constants/app.const";
import { NOTI_CODE } from "constants/firestore.const";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { AUTH_MODE_TYPE } from "models/auth.model";
import { selectAuthMode, selectNamespaceId } from "store/auth/auth.selector";
import {
  selectFundraising,
  selectFundraisingLoading,
  selectFundraisingProgress,
  selectPossibleEditFundraising,
} from "store/fundraising/fundraising.selector";
import { fundraisingActions } from "store/fundraising/fundraising.slice";
import {
  selectOrganisation,
  selectOrganisationProgress,
} from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";
import { db } from "utils/firebase.util";
import { scrollIntoView } from "utils/system.util";
import { ProgressSection, StatusBar, StatusFlag } from "../components";
import {
  AdminOrganisationSection,
  AdminStatusSection,
  ContactSection,
  PitchDeckSection,
  RasingInfoSection,
} from "./components";
import { StatusIndicator } from "models/common.model";

export const CompanyFundraisingDetail = () => {
  const [open, setOpen] = useState(false);
  const [activeSection, setActiveSection] =
    useState<RefObject<HTMLDivElement>>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { fundraisingId: fundraisingParamsId } = useParams();
  const namespaceId = useAppSelector(selectNamespaceId);
  const { id, status, message, organisation } =
    useAppSelector(selectFundraising);
  const {
    id: organisationId,
    displayName,
    logoUrl,
    status: organisationStatus,
    message: organisationMessage,
    mfScore,
  } = useAppSelector(selectOrganisation);
  const fundraisingProgressList = useAppSelector(selectFundraisingProgress);
  const fundraisingLoading = useAppSelector(selectFundraisingLoading);
  const organisationProgress = useAppSelector(selectOrganisationProgress);
  const isPossibleEditFundraising = useAppSelector(
    selectPossibleEditFundraising
  );
  const authMode = useAppSelector(selectAuthMode);
  const isAdmin = authMode === AUTH_MODE_TYPE.ADMIN;
  const isOrganisationAdmin = _.includes(location.pathname, "/admin/company");

  const raisingInfoRef = useRef<HTMLDivElement>(null);
  const pitchDeckRef = useRef<HTMLDivElement>(null);
  const contactsRef = useRef<HTMLDivElement>(null);

  const isCompleteSettings = !organisationProgress.find(
    (item) => !item.isComplete
  );
  const isDisableSubmit = !isCompleteSettings;

  const handleActiveSection = (activeSection: RefObject<HTMLDivElement>) => {
    setActiveSection(activeSection);

    setTimeout(() => {
      setActiveSection(undefined);
    }, 1000);
  };

  const progressList = fundraisingProgressList.map((item) => ({
    ...item,
    onClick: () => {
      switch (item.label) {
        case "Raising Info": {
          scrollIntoView(raisingInfoRef);
          handleActiveSection(raisingInfoRef);
          break;
        }
        case "Pitch Deck": {
          scrollIntoView(pitchDeckRef);
          handleActiveSection(pitchDeckRef);
          break;
        }
        case "Contacts for investors": {
          scrollIntoView(contactsRef);
          handleActiveSection(contactsRef);
          break;
        }
      }
    },
  }));

  if (!mfScore) {
    progressList.push({
      label: "MF Trust score",
      isComplete: !!mfScore,
      onClick: () => navigate(PATH.COMPANY_MF_SCORE),
    });
  }

  const isCompleteFundraising = !progressList.find((item) => !item.isComplete);

  const handleSubmitFundraising = () => {
    if (!namespaceId) return;
    const notificationRef = collection(db, "notifications");

    if (
      isCompleteSettings &&
      status === StatusIndicator.DRAFT &&
      organisationStatus === StatusIndicator.DRAFT
    ) {
      dispatch(
        organisationActions.submitOrganisation({
          organisationId,
          callback: async () => {
            await addDoc(notificationRef, {
              code: `${NOTI_CODE.REVIEW_ORG}__${organisationId}__${organisationId}__${StatusIndicator.REVIEW}`,
              receiverId: namespaceId,
              createdAt: new Date().valueOf(),
              isRead: false,
              unreadCount: 1,
            });
          },
        })
      );
    }

    if (id) {
      dispatch(
        fundraisingActions.submitFundraising({
          id,
          callback: async () => {
            setOpen(false);

            const userRef = doc(db, "users", organisationId);
            const userData = await getDoc(userRef);

            if (!userData.exists()) {
              await setDoc(userRef, {
                id: organisationId,
                displayName,
                avatar: logoUrl,
                type: "founder",
              });
            }

            await addDoc(notificationRef, {
              code: `${NOTI_CODE.REVIEW_FUND}__${organisationId}__${id}__${StatusIndicator.REVIEW}`,
              receiverId: namespaceId,
              createdAt: new Date().valueOf(),
              isRead: false,
              unreadCount: 1,
            });

            // when org and round flagged - submit both (3)
            if (
              status === StatusIndicator.FLAGGED &&
              organisationStatus === StatusIndicator.FLAGGED
            ) {
              dispatch(
                organisationActions.submitOrganisation({
                  organisationId: organisationId,
                  callback: async () => {
                    await addDoc(notificationRef, {
                      code: `${NOTI_CODE.REVIEW_ORG}__${organisationId}__${organisationId}__${StatusIndicator.REVIEW}`,
                      receiverId: namespaceId,
                      createdAt: new Date().valueOf(),
                      isRead: false,
                      unreadCount: 1,
                    });
                  },
                })
              );
            }
          },
        })
      );
    }
  };

  useEffect(() => {
    if (fundraisingParamsId) {
      dispatch(
        fundraisingActions.getFundraising({ id: fundraisingParamsId, isAdmin })
      );
    }
  }, [dispatch, fundraisingParamsId, isAdmin]);

  useEffect(() => {
    if (isAdmin && organisation?.id) {
      dispatch(
        organisationActions.getOrganisation({
          id: organisation?.id,
          isAdmin,
        })
      );
    } else {
      dispatch(organisationActions.getCompanyOrganisation());
    }
  }, [dispatch, isAdmin, organisation?.id]);

  return (
    <Wrapper>
      <StatusBar status={status} />

      {organisationStatus === StatusIndicator.FLAGGED &&
        isCompleteSettings &&
        !isAdmin && (
          <Warning
            icon={<WarningIcon />}
            text={`Your Company request was rejected by the moderator.${
              organisationMessage ? ` "${organisationMessage}"` : ""
            }`}
            backgroundColor="#fff1f0"
            color="#b4453d"
            hasButton
            buttonText="Complete Settings"
            handleClickButton={() => navigate(PATH.COMPANY_SETTINGS)}
          />
        )}

      {!isCompleteSettings && !isAdmin && (
        <Warning
          //text="Complete your Company Profile Settings and get your MF Trust Score before you open your Funding round."
          text="Complete your Company Profile Settings before you open your Funding round."
          hasButton
          buttonText="Complete Settings"
          handleClickButton={() => navigate(PATH.COMPANY_SETTINGS)}
        />
      )}

      {status === StatusIndicator.FLAGGED && !isAdmin && (
        <Warning
          icon={<WarningIcon />}
          text={`Your Funding round request was rejected by the moderator.${
            message ? ` "${message}"` : ""
          }`}
          backgroundColor="#fff1f0"
          color="#b4453d"
        />
      )}

      <Header>
        <PageTitle>Funding round</PageTitle>

        <StatusFlag status={status} />
      </Header>

      <MainBox>
        <LeftBox>
          {isAdmin && status !== StatusIndicator.DRAFT && (
            <AdminStatusSection />
          )}

          {isAdmin && !isOrganisationAdmin && <AdminOrganisationSection />}

          <ScrollToWrapper ref={raisingInfoRef} activeRef={activeSection}>
            <RasingInfoSection />
          </ScrollToWrapper>

          <ScrollToWrapper ref={pitchDeckRef} activeRef={activeSection}>
            <PitchDeckSection />
          </ScrollToWrapper>

          <ScrollToWrapper ref={contactsRef} activeRef={activeSection}>
            <ContactSection />
          </ScrollToWrapper>
        </LeftBox>

        <RightBox>
          <ProgressSection
            progressList={progressList}
            buttonText="Open my Funding round"
            completeText="Congratulations! Now you can submit your Funding round for approval."
            tooltipText="You need to complete all the sections below in order to publish your Funding round and start raising funds through our network of investors."
            buttonDisabled={
              !isCompleteFundraising || !isPossibleEditFundraising
            }
            handleClickButton={() => setOpen(true)}
            hideFooter={isAdmin}
          />
        </RightBox>
      </MainBox>

      <ConfirmModal
        title={
          isDisableSubmit
            ? "Fill out Company Settings"
            : "Open my Funding round"
        }
        subTitle={
          isDisableSubmit
            ? "Fill out Company Settings to open your Funding round"
            : "You are about to submit your Funding round and expose it to our pool of Investors"
        }
        isOpen={open}
        onClose={() => setOpen(false)}
        callback={
          isDisableSubmit
            ? () => navigate(PATH.COMPANY_SETTINGS)
            : handleSubmitFundraising
        }
        loading={fundraisingLoading}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const MainBox = styled.div`
  display: grid;
  grid-template-columns: auto 352px;
  gap: 32px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    grid-template-columns: 1fr;
  }
`;

const LeftBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 36px;
`;

const RightBox = styled.div`
  width: 352px;

  & > div {
    position: sticky;
    top: 20px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;
