import { call, put, takeLatest } from "@redux-saga/core/effects";
import adminApi from "api/admin.api";

function* getAdminStats() {
  try {
    const result = yield call(adminApi.adminStats);
    yield put({ type: "ADMIN_STATS_SUCCESS", payload: result.node });
  } catch (err) {
    console.log(err);
    yield put({ type: "ADMIN_STATS_ERROR", payload: { message: err.message } });
  }
}

export default function* adminSaga() {
  yield takeLatest("ADMIN_STATS_REQUEST", getAdminStats);
}
